import {configureStore} from "@reduxjs/toolkit";
import { apiSlice} from "./rtk-api/apiSlice";
import {createWrapper} from 'next-redux-wrapper';
import {queryErrorLogger} from "./rtk-middleware/queryErrorLogger";
import {axiosMiddleware} from "./rtk-middleware/axiosMiddleware";

const makeStore = (context) =>
	configureStore({
		reducer: {
			[apiSlice.reducerPath]: apiSlice.reducer,
		},
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				thunk: {
					extraArgument: {
						cookies: context.req?.cookies,
						headers: context.req?.headers
					},
				}
			})
				.prepend(axiosMiddleware(context.req?.cookies))
				// .concat(queryErrorLogger)
				.concat(apiSlice.middleware)
		// devTools: true,
	})

export const wrapper = createWrapper(makeStore, {debug: process.env.NODE_ENV === 'development'});
