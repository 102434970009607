import axios from 'axios'

export const axiosMiddleware = (cookies) => store => next => action => {
	axios.defaults.withCredentials = true;
	// this only works on server side... which is what we want!
	if (cookies !== undefined) {
		axios.defaults.headers.common['X-XSRF-TOKEN'] = cookies['XSRF-TOKEN'] ?? null
		
		// Build a cookie string from object
		const cookieValue = Object.entries(cookies)
			.map(([k, v]) => `${k}=${v}`)
			.join('; ');
		
		axios.defaults.headers.common['Cookie'] = `${cookieValue}`
		
		// if these are removed from the request a 2nd time (for testing purposes),
		// the reqest will still work. Its only when they are changed that they dont work
		// server side
		axios.defaults.headers.common['referer'] = process.env.NEXT_PUBLIC_SSR_REFERRER
		axios.defaults.headers.common['origin'] = process.env.NEXT_PUBLIC_SSR_REFERRER
	}
	
	return next(action)
}
