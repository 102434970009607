import { isRejectedWithValue } from '@reduxjs/toolkit'
import {useGeneralStore} from "../stores/general";

export const queryErrorLogger = (api) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		switch(action.payload.status) {
			case 500:
			case 501:
			case 502:
			case 503:
				useGeneralStore.setState({globalMessage: {
					error: true,
					message: 'There was an issue and we have been alerted. Please try again later.'
				}})
				break;
			// case 401:
			// 	window.location.href = process.env.NEXT_PUBLIC_AUTH_URL + '/login'
			// 	break;
			case 403:
				useGeneralStore.setState({globalMessage: {
					error: true,
					message: 'You are not allowed to perform this action'
				}})
				break;
			case 404:
				useGeneralStore.setState({globalMessage: {
					warning: true,
					message: '404 not found'
				}})
				break;
			case 429:
				useGeneralStore.setState({globalMessage: {
					warning: true,
					message: 'Please try again in a few minutes'
				}})
				break;
			default:
				break;
		}
	}
	
	return next(action)
}
